/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 2;
  margin: 0;
}
div.sub1 > .item {
  padding: 0 10px;
}
div.sub1 > .item > .menu {
  line-height: 20px;
}
div.sub2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
  box-sizing: border-box;
  padding: 0 100px;
}
div.sub2 > .item {
  border-right: 2px solid #fff;
  padding: 0 40px;
}
div.sub2 > .item.init {
  padding-left: 0;
}
div.sub2 > .item.exit {
  padding-right: 0;
  border: none;
}
div.sub2 > .item > .menu {
  color: #fff;
  text-decoration: none;
  font-family: 'RobotoCondensedMedium', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  line-height: 20px;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus,
div.sub2 > .item > .menu.path {
  color: #ebbca0;
}
.desk {
  max-width: 94%;
}
#home {
  margin-left: -197px;
  width: 394px;
}
.section--content {
  background-size: 744px 502px;
  background-position: 6% 100px;
}
.section--south {
  margin-top: 0;
}
.maincontent {
  width: 24.14772727%;
  margin-top: 60px;
}
.moodcontent {
  bottom: 20px;
}
.service_mymuerren {
  width: 160px;
  height: 52px;
}
.footarea {
  float: right;
  width: 50.56818182%;
  margin-right: -0.56818182%;
}
.footunit {
  float: left;
  margin: 25px 1.12359551%;
  width: 47.75280899%;
}
@media (max-width: 1299px) {
  div.sub2 > .item {
    padding: 0 20px;
  }
  div.link a.open {
    padding: 18px 30px;
    min-width: 180px;
    background-position: 9px 50%;
  }
  .wrapper {
    font-size: 17px;
  }
  h1,
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 24px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part {
  width: 100%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 0.74906367%;
  margin-left: 0.74906367%;
}
.side .part {
  margin-right: 0.74906367%;
  margin-left: 0.74906367%;
  width: 98.50187266%;
}
.side .tiny {
  width: 31.83520599%;
}
.side > .slim {
  width: 33.33333333%;
}
.side > .slim .foot,
.side > .slim .part {
  margin-right: 2.24719101%;
  margin-left: 2.24719101%;
}
.side > .slim .part {
  width: 95.50561798%;
}
.side > .slim .tiny {
  width: 95.50561798%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .foot {
  margin-right: 0.56179775%;
  margin-left: 0.56179775%;
}
.base .part {
  margin-right: 0.56179775%;
  margin-left: 0.56179775%;
  width: 98.87640449%;
}
.base .tiny {
  width: 23.87640449%;
}
.base > .slim {
  width: 25%;
}
.base > .slim .foot,
.base > .slim .part {
  margin-right: 2.24719101%;
  margin-left: 2.24719101%;
}
.base > .slim .part {
  width: 95.50561798%;
}
.base > .slim .tiny {
  width: 95.50561798%;
}
div.main {
  width: 100%;
  margin-left: 0;
}
div.side {
  float: right;
  margin-top: 40px;
  width: 75.85227273%;
  margin-right: -0.56818182%;
  margin-left: 0;
}
div.base {
  width: 101.13636364%;
  margin-left: -0.56818182%;
}
div.main div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */